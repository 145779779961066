import React, { ChangeEvent, useState } from "react";
import "./RegistrationForm.css"; // Make sure to have a CSS file to style your form.
import { registerUser } from "../services/auth.service";

const RegistrationForm = ({ onLoginClick }: { onLoginClick: () => void }) => {
  const [form, setForm] = useState({
    data: {
      email: "",
      username: "",
      password: "",
      repeatpassword: "",
    },
    passwordError: "",
  });
  const [isRegistrationSuccessfull, setIsRegistrationSuccessfull] =
    useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isPasswordValid()) {
      setIsLoading(true);

      try {
        const truncatedData = {
          ...form.data,
          email: form.data.email.trim(),
          username: form.data.username.trim(),
        };
        const response = await registerUser(truncatedData);
        console.log("User registered successfully:", response.message);
        setIsRegistrationSuccessfull("success");
      } catch (error) {
        console.error("Registration failed:", error);
        setIsRegistrationSuccessfull("failure");
      }
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setForm((prevForm) => ({
      data: {
        ...prevForm.data,
        [id]: value,
      },
      passwordError: prevForm.passwordError,
    }));
  };

  const isPasswordValid = () => {
    const { password, repeatpassword } = form.data;

    if (password !== repeatpassword) {
      setForm({ ...form, passwordError: "Пароли должны совпадать!" });
      return false;
    } else {
      setForm({ ...form, passwordError: "" });
      return true;
    }
  };

  const getForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <label className="form-label" htmlFor="email">
          E-mail
        </label>
        <input
          className="form-input"
          type="text"
          id="email"
          value={form.data.email}
          onChange={handleInputChange}
          required
        />

        <label className="form-label" htmlFor="username">
          Логин
        </label>
        <input
          className="form-input"
          type="text"
          id="username"
          value={form.data.username}
          onChange={handleInputChange}
          required
        />

        <label className="form-label" htmlFor="password">
          Пароль
        </label>
        <input
          className="form-input"
          type="password"
          id="password"
          value={form.data.password}
          onChange={handleInputChange}
          required
        />

        <label className="form-label" htmlFor="repeatpassword">
          Подтвердите пароль
        </label>
        <input
          className="form-input"
          type="password"
          id="repeatpassword"
          value={form.data.repeatpassword}
          onChange={handleInputChange}
          required
        />

        {form.passwordError && (
          <div className="text error-message">{form.passwordError}</div>
        )}

        <button type="submit" style={{ width: "300px" }}>
          {isLoading ? (
            <div className="spinner spinner-small"></div>
          ) : (
            "Регистрация"
          )}
        </button>
        <div className="small-text">
          Регистрируясь на сайте вы даете согласие на{" "}
          <a href="/personal-data" target="_blank" rel="noreferrer">
            обработку персональных данных
          </a>
        </div>
      </form>
    );
  };

  const getSuccessMessage = () => {
    return (
      <div className="container-v center" style={{ margin: "80px" }}>
        <div className="text" style={{ fontSize: "22px" }}>
          Вы успешно зарегистрированы!
        </div>
        <div
          className="text registration-successful"
          style={{ marginTop: "20px" }}
        >
          Мы отправили вам письмо для подтверждения адреса электронной почты.
          Пожалуйста, проверьте свой почтовый ящик и следуйте инструкциям в
          письме. Если письмо не пришло, проверьте папку спам!
        </div>
      </div>
    );
  };

  const getErrorMessage = () => {
    return (
      <div className="container-v center">
        <div className="text">Что-то пошло не так</div>
        <button
          className="button"
          onClick={() => setIsRegistrationSuccessfull("")}
        >
          Попробовать еще раз
        </button>
      </div>
    );
  };

  return isRegistrationSuccessfull === ""
    ? getForm()
    : isRegistrationSuccessfull === "success"
    ? getSuccessMessage()
    : getErrorMessage();
};

export default RegistrationForm;
