import React, { FC, forwardRef } from "react";
import "./ModalPopUp.css";
import { Bubble } from "./Sections/InfoSection";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  bubble: Bubble;
}
const ModalPopUp: FC<ModalProps> = ({ isOpen, onClose, bubble }) => {
  if (!isOpen) return null;

  const handleOutsideClick = (event: MouseEvent) => {
    if (document.getElementById("modal_overlay") === event.target) {
      onClose();
    }
  };

  if (isOpen) {
    document.addEventListener("mousedown", handleOutsideClick);
  }

  return (
    <div id="modal_overlay" className="modal-overlay">
      <div id="modal" className="modal">
        <img
          src="close.svg"
          alt="close"
          style={{
            cursor: "pointer",
            position: "absolute",
            left: "94%",
            top: "6%",
            width: "4vh",
          }}
          onClick={onClose}
        />
        <div className="container" style={{ width: "100%", gap: "10px" }}>
          <div className="container-v modal-left">
            <div className="text popup-label">{bubble.label}</div>
            <div
              className="text popup-text"
              dangerouslySetInnerHTML={{ __html: bubble.text }}
            ></div>
            {bubble.buttonText && (
              <a href={bubble.buttonLink} className="text popup-button">
                {bubble.buttonText}
              </a>
            )}
            {bubble.socials && (
              <div className="container socials">
                {bubble.socials.map((social) => (
                  <a
                    key={social.platform}
                    href={social.link}
                    className="social-link"
                  >
                    <img src={`${social.platform}.svg`} alt={social.platform} />
                  </a>
                ))}
              </div>
            )}
          </div>
          <div
            className="container center"
            style={{ width: "50%", padding: "10px" }}
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: `${bubble.socials ? "95%" : "100%"}`,
                objectFit: "contain",
                flex: 1,
                position: `${bubble.socials ? "absolute" : "unset"}`,
                bottom: 0,
              }}
              src={
                bubble.socials
                  ? `bubbles/big-${bubble.image}`
                  : `bubbles/${bubble.image}`
              }
              alt={bubble.label}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopUp;
