import React from "react";
import NavigationBar from "../components/NavigationBar";
import { Outlet } from "react-router-dom";
import FooterSection from "../components/Sections/FooterSection";

function Main() {
  return (
    <div id="app">
      <NavigationBar />
      <div className="background">
        <Outlet />
        <FooterSection />
      </div>
    </div>
  );
}

export default Main;
