import React from "react";

import "./Quest.css";
import { useLocation } from "react-router-dom";
import { isAccessTokenValid } from "../utils/authUtils";

function Quest() {
  const { pathname } = useLocation();
  const id = pathname.replace("/quest/", "");

  const getStart = () => {
    return (
      <h2 style={{ color: "white", textAlign: "center" }}>
        Аюми пытается взломать вам ****, ей нужен пароль. Найдите его на
        площадке фестиваля. Начало на стенде Няф.
      </h2>
    );
  };

  return (
    <div className="content container-v center">
      <h1 className="header">QUEST</h1>
      <div className="container-v center">
        {!isAccessTokenValid() ? (
          <h2
            className="schedule-item-title container center"
            style={{ margin: "15px", textAlign: "center" }}
          >
            Для участия в квесте необходимо авторизоваться на сайте!
          </h2>
        ) : (
          <div className="container-v center">
            {id === "start" && getStart()}
            {id !== "start" && (
              <h2 style={{ color: "white", textAlign: "center" }}>{id}</h2>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Quest;
