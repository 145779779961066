import React, { useState } from "react";
import "./LoginDrawer.css";
import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import { UserProfile } from "../types/UserProfile";
import { getUserPhotoLink, isAccessTokenValid } from "../utils/authUtils";

interface LoginDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  isUserLoggedIn: boolean;
  setIsUserLoggedIn: (isUserLoggedIn: boolean) => void;
  onLogout: () => void;
  onLogin: () => void;
  userProfile?: UserProfile;
}

const LoginDrawer: React.FC<LoginDrawerProps> = ({
  isOpen,
  onClose,
  isUserLoggedIn,
  setIsUserLoggedIn,
  onLogout,
  onLogin,
  userProfile,
}) => {
  const isDesktop = window.screen.width > 1450;

  const drawerStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    right: isOpen ? "0" : isDesktop ? "-600px" : "-90vw",
    width: isDesktop ? "600px" : "90vw",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.2)",
    transition: "right 0.3s ease-in-out",
    zIndex: 1000,
    overflowY: "auto",
  };

  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "block" : "none",
    zIndex: 999,
  };

  const [isLogin, setIsLogin] = useState(true);

  const loginUser = () => {
    const token = isAccessTokenValid();
    if (token) {
      setIsLogin(true);
      setIsUserLoggedIn(true);
      onLogin();
    } else {
      setIsLogin(false);
      setIsUserLoggedIn(false);
    }
  };

  return (
    <div>
      <div className="container-v center" style={drawerStyle}>
        <div>
          <img
            src={getUserPhotoLink() ?? "/Userpic.png"}
            alt="Userpic"
            className="userpic"
          />
        </div>
        <div>
          {isUserLoggedIn ? (
            <div className="login-register">
              <span className="text">{userProfile?.username ?? ""}</span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {isUserLoggedIn ? (
          <div
            className="container-v center"
            style={{
              gap: "20px",
            }}
          >
            {/* <a className="link-text" href="/my-profile">
              Мой профиль
            </a> */}
            <a className="link-text" href="/my-applications">
              Мои заявки
            </a>
            <button className="logout" onClick={() => onLogout()}>
              Выйти
              <img
                src="/Logout.svg"
                alt="Logout"
                style={{ marginLeft: "14px", width: "35px", height: "35px" }}
              />
            </button>
          </div>
        ) : isLogin ? (
          <>
            <LoginForm onLogin={() => loginUser()} />
            <div
              className="text"
              style={{
                marginTop: "40px",
                textAlign: "center",
                padding: "0px 20px",
              }}
            >
              Еще нет аккаунта?{" "}
              <span className="register-link" onClick={() => setIsLogin(false)}>
                Зарегистрироваться
              </span>
            </div>
            <div className="small-text">
              Регистрируясь на сайте вы даете согласие на{" "}
              <a href="/personal-data" target="_blank" rel="noreferrer">
                обработку персональных данных
              </a>
            </div>
          </>
        ) : (
          <>
            <RegistrationForm
              onLoginClick={() => {
                setIsLogin(true);
              }}
            />
            <div className="text" style={{ marginTop: "10px" }}>
              <span className="register-link" onClick={() => setIsLogin(true)}>
                Назад
              </span>
            </div>
          </>
        )}
      </div>
      <div style={overlayStyle} onClick={onClose}></div>
    </div>
  );
};

export default LoginDrawer;
