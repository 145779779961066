import React, { useEffect, useState } from "react";
import "./NavigationBar.css";
import LoginDrawer from "./LoginDrawer";
import MenuDrawer from "./MenuDrawer";
import { useLocation } from "react-router-dom";
import { Page, getPages } from "../services/pageService";
import {
  getUserPhotoLink,
  isAccessTokenValid,
  logoutUser,
} from "../utils/authUtils";
import { BASE_URL } from "../config";
import { UserProfile } from "../types/UserProfile";
import { getProfile } from "../services/auth.service";

const NavigationBar = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // Change this state based on user authentication
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contestPages, setContestPages] = useState<Page[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile>({ username: "" });
  const { pathname } = useLocation();

  useEffect(() => {
    async function fetchPages() {
      try {
        const pagesData = await getPages();
        setContestPages(
          pagesData
            .filter((p) => p.route.includes("contest"))
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        );
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    async function fetchData() {
      if (isAccessTokenValid()) {
        setIsUserLoggedIn(true);

        await getUserProfile();
      }
    }

    fetchPages();
    fetchData();
  }, []);

  const getUserProfile = async () => {
    try {
      const profile = await getProfile();
      setUserProfile(profile);
    } catch (error) {
      // Handle error
      console.error("Error fetching profile:", error);
    }
  };

  const onLogin = async () => {
    if (isAccessTokenValid()) {
      setIsUserLoggedIn(true);

      await getUserProfile();
    }
  };

  const scrollToTop = () => {
    if (pathname !== "/") {
      window.location.pathname = "/";
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const showLoginDrawer = () => {
    setDrawerOpen(true);
  };
  const hideLoginDrawer = () => {
    setDrawerOpen(false);
  };

  const showLoginDrawerMobile = () => {
    setDrawerOpen(true);
    setMobileMenuOpen(false);
  };

  const showMobileMenuDrawer = () => {
    setMobileMenuOpen(true);
  };
  const hideMobileMenuDrawer = () => {
    setMobileMenuOpen(false);
  };

  const onMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event?.currentTarget?.parentElement?.classList.add("menu-item-active");
  };
  const onMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event?.currentTarget?.parentElement?.classList.remove("menu-item-active");
  };

  const getSvg = () => {
    return (
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.25L7.5 7.75L14 1.25"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  const logout = () => {
    setIsUserLoggedIn(false);
    logoutUser();
  };

  return (
    <div>
      <nav className="container navbar">
        <div className="logo" onClick={scrollToTop}>
          <img src="/NYAF_pink.svg" alt="NYAF" />
        </div>
        <div className="menu">
          <div className="menu-item">
            <a href="/about" className="link">
              <span className="menu-text">О нас</span>
            </a>
          </div>
          <div className="menu-item">
            <div className="link">
              <span className="menu-text">Информация</span>
              <span className="menu-icon">{getSvg()}</span>
            </div>
            <div
              className="submenu"
              style={{ width: "246px" }}
              onMouseEnter={(event) => onMouseEnter(event)}
              onMouseLeave={(event) => onMouseLeave(event)}
            >
              <a href="/what-where-when" className="dropdown-link">
                Что? Где? Когда?
              </a>
              <a href="/tickets" className="dropdown-link">
                Билеты
              </a>
              <a href="/faq" className="dropdown-link">
                FAQ
              </a>
              <a href="/rules" className="dropdown-link">
                Правила фестиваля
              </a>
              {/* <a href="/schedule" className="dropdown-link">
                Программа фестиваля
              </a> */}
            </div>
          </div>
          <div className="menu-item">
            <div className="link">
              <span className="menu-text">Конкурсы</span>
              <span className="menu-icon">{getSvg()}</span>
            </div>
            <div
              className="submenu"
              style={{ width: "246px" }}
              onMouseEnter={(event) => onMouseEnter(event)}
              onMouseLeave={(event) => onMouseLeave(event)}
            >
              {contestPages.map((p) => (
                <a
                  key={p.pageId}
                  href={`/${p.route}`}
                  className="dropdown-link"
                >
                  {p.title}
                </a>
              ))}
              <a href="/all-applications" className="dropdown-link">
                Пришедшие заявки
              </a>
              {/*<a href="/photocosplay/vote" className="dropdown-link">
                Голосование Фотокосплей
              </a> */}
            </div>
          </div>
          <div className="menu-item">
            <div className="link">
              <span className="menu-text">Фойе</span>
              <span className="menu-icon">{getSvg()}</span>
            </div>
            <div
              className="submenu"
              style={{ width: "246px" }}
              onMouseEnter={(event) => onMouseEnter(event)}
              onMouseLeave={(event) => onMouseLeave(event)}
            >
              <a href="/hall/fair" className="dropdown-link">
                Ярмарка
              </a>
              <a href="/hall/authors" className="dropdown-link">
                Аллея Авторов
              </a>
              <a href="/hall/accreditation" className="dropdown-link">
                Аккредитация
              </a>
              {/* <a href="/all-applications-hall" className="dropdown-link">
                Пришедшие заявки
              </a> */}
            </div>
          </div>
          <div className="menu-item">
            <a href="/partners" className="link">
              Партнеры
            </a>
          </div>
        </div>
        <div className="login-section">
          {isUserLoggedIn ? (
            <div>
              <button onClick={() => showLoginDrawer()}>
                {userProfile?.username}
                <img
                  src={getUserPhotoLink() ?? "/Userpic.png"}
                  alt="Userpic"
                  style={{
                    marginLeft: "14px",
                    width: "44px",
                    height: "44px",
                    borderRadius: "50%",
                  }}
                />
              </button>
            </div>
          ) : (
            <div>
              <button onClick={() => showLoginDrawer()}>
                Войти{" "}
                <img
                  src="/Login.svg"
                  alt="Login"
                  style={{ marginLeft: "14px" }}
                />
              </button>
            </div>
          )}
        </div>
        <div className="buy-ticket">
          <button
            onClick={() => {
              window.location.pathname = "/tickets";
            }}
          >
            КУПИТЬ БИЛЕТ
          </button>
        </div>
        <div className="burger-menu" onClick={() => showMobileMenuDrawer()}>
          <img src="/burger.svg" alt="menu" />
        </div>
      </nav>
      <LoginDrawer
        isUserLoggedIn={isUserLoggedIn}
        setIsUserLoggedIn={setIsUserLoggedIn}
        onLogout={logout}
        onLogin={onLogin}
        isOpen={isDrawerOpen}
        onClose={hideLoginDrawer}
        userProfile={userProfile}
      />
      <MenuDrawer
        isUserLoggedIn={isUserLoggedIn}
        onLogout={logout}
        isOpen={isMobileMenuOpen}
        onClose={hideMobileMenuDrawer}
        showLoginDrawer={showLoginDrawerMobile}
        userProfile={userProfile}
      />
    </div>
  );
};

export default NavigationBar;
