import React from "react";

import "./Tickets.css";
import TicketsWidget from "../components/TicketsWidget";
import OfflineShopsWidget from "../components/OfflineShopsWidget";

function Tickets() {
  return (
    <div className="contest longread">
      <div className="contest-section container-v center">
        <div className="content tickets-content">
          <div
            className="container-v center"
            // dangerouslySetInnerHTML={{ __html: ticketsData }}
          >
            <h1 className="header" style={{ color: "white" }}>
              БИЛЕТЫ
            </h1>
            {/* <h2 className="text" style={{ marginBottom: "30px" }}>
              Вы можете приобрести билеты на фестиваль в магазинах наших
              партнеров:{" "}
            </h2>
            <OfflineShopsWidget />
             */}
            <TicketsWidget />
            <h2
              className="text"
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                color: "white",
              }}
            >
              Билеты на фестиваль NYAF можно приобрести только онлайн!
            </h2>
            <h2
              className="text"
              style={{ marginBottom: "30px", marginTop: "0px", color: "white" }}
            >
              На входе в день фестиваля билеты продаваться не будут!
            </h2>
            <div className="body-text" style={{ marginTop: "20px" }}>
              Количество билетов ограничено. Следите за новостями в наших
              социальных сетях:{" "}
              <a
                className="more-text"
                href="https://vk.com/nyafest"
                target="_blank"
                rel="noreferrer"
              >
                VK
              </a>{" "}
              и
              <a
                className="more-text"
                href="https://t.me/nyafestival"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tickets;
