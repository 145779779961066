import React from "react";
import "./InfoSection.css";
import BubbleWithPopUp from "../BubbleWithPopUp";
import TicketsWidget from "../TicketsWidget";
import OfflineShopsWidget from "../OfflineShopsWidget";
import Marquee from "react-fast-marquee";

export type Bubble = {
  id: number;
  label: string;
  image: string;
  text: string;
  buttonText?: string;
  buttonLink?: string;
  socials?: { platform: string; link: string }[];
  color: string;
};

const awaitsBubbles: Bubble[] = [
  {
    id: 1,
    label: "КОНКУРС КОСПЛЕЯ",
    image: "cosplay.png",
    text: `<p>Косплей (от англ. costume play — «костюмированная
      игра») — перевоплощение в образ персонажа 
      из аниме, игр, кино, сералов и других источников,
      а так же изготовление для этих образов костюма 
      и атрибутики.</p><p>
      Конкурс косплея — традиционная часть фестивалей
      современной культуры, где участники со всей России
      соревнуются в том, у кого лучше удалось изготовить
      костюм и передать характер персонажа на сцене.</p><p>
      Победители во всех номинациях получат ценные
      призы от нас, наших друзей и партнёров!</p>`,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 2,
    label: "COVER DANCE",
    image: "dance.png",
    text: `<p>K-pop — музыкальный жанр, возникший в Южной Корее
     и вобравший в себя элементы западного электропопа, хип-хопа, 
     танцевальной музыки и современного ритм-н-блюза. 
     Появившись как музыкальный жанр, <nobr>K-pop</nobr> превратился в масштабную 
     субкультуру с миллионами поклонников во всём мире и наш 
     фестиваль не остался в стороне!</p><p>Cover dance - 
     популярное танцевальное направление, которому традиционно
      посвящена часть нашего фестиваля. Участники со всей России 
      соревнуются в сценической хореографии, постановке номера 
      и качестве исполнения.</p><p>Победители номинации получат 
      памятные призы от нашего фестиваля и партнеров!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 3,
    label: "КОНКУРС ВОКАЛА",
    image: "vocal.png",
    text: `<p>Конкурс вокала – неотъемлемая часть фестиваля NYAF. 
    Участники  номинации показывают живое вокальное выступление на 
    сцене, как правило, сопровождающееся сценической постановкой. </p> 

    <p>Здесь можно услышать аниме-опенинги, саундтреки из любимых
     видеоигр и фильмов, сцены из мьюзиклов, каверы на песни известных 
     <nobr>k-pop</nobr> исполнителей и многое другое.</p> 
    
    <p>Соревнуясь в качестве исполнения, постановке номера и 
    вокальной технике, участники данной номинации могут 
    выиграть ценные призы от нашего фестиваля и партнеров!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 4,
    label: "ФАНАТСКИЕ СТЕНДЫ",
    image: "stands.png",
    text: `<p>Фанатские стенды представляют собой зону с 
    декорациями по определенному фандому: аниме, видеоигре, 
    фильму или книге, комиксу, анимации или по определенной тематике. </p> 

    <p>На протяжении всей работы фанатского 
    стенда в зоне находятся участники в образах и проводят 
    различного рода активности и конкурсы для всех
     участников и гостей фестиваля.</p> 
    
    <p>Интересно, какие стенды будут представлены 
    на NYAF? Следи за новостями в нашей группе и узнай первым!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 5,
    label: "ЯРМАРКА",
    image: "fair.png",
    text: `<p>Во время фестиваля вас будет ждать разнообразная ярмарка, 
    где каждый желающий сможет найти фандомную атрибутику на любой вкус. </p> 

    <p>В холле будут представлены <nobr>geek-магазины</nobr> как из Уфы, 
    так и из других городов. Наши друзья представят огромный выбор 
    товаров от маленьких значков и наклеек до коллекционных фигурок и ростовых подушек. </p> 
    
    <p>Гости фестиваля смогут найти уникальную аниме, geek, 
    <nobr>k-pop</nobr> атрибутику и не только. Также на ярмарке вы сможете приобрести
     на память сувенирную продукцию от нашего фестиваля!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 6,
    label: "АЛЛЕЯ АВТОРОВ",
    image: "authors.png",
    text: `<p>Аллея авторов - место, где художники и 
    мастера могут представить своё творчество. 
    Здесь вы можете приобрести уникальные авторские 
    товары, познакомиться с творческими людьми и даже 
    заказать свой портрет в авторском стиле!</p>
    <p>Мы всегда рады новым авторам! Если вы создаёте 
    собственные иллюстрации, комиксы или уникальные 
    handmade-товары и хотите поделиться своим 
    творчеством с другими — добро пожаловать к нам!</p>`,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "white",
  },
  {
    id: 7,
    label: "ИГРОВАЯ ЗОНА",
    image: "games.png",
    text: `<p>Игровая зона - место на фестивале, где вы сможете отдохнуть от толпы. 
    Здесь вы сможете собраться с друзьями или найти новую компанию 
    для партии в настольную или видеоигру. </p>

    <p>Вы можете сможете воспользоваться открытой игротекой 
    настольных игр от наших партнеров. Это отличная возможность 
    весело провести время в перерыве между выступлениями участников
     и познакомить близких с культурой настольных игр.</p>
     <p>Также в игровой зоне Вас будут ждать <nobr>Free-to-play</nobr>
      ритм-игры, где Вы сможете проверить свою реакцию и 
      чувство ритма и файтинги для встречи «один на один» 
      против компьютерного игрока или вашего друга.</p>
    `,
    color: "white",
  },
  {
    id: 8,
    label: "КОНКУРСЫ И КВЕСТЫ",
    image: "quests.png",
    text: `<p>На фестивале NYAF каждый гость сможет 
    принять участие в конкурсах и квестах.</p>

    <p>Следите за нашим маскотом Аюми-тян! 
    Она приготовила много интересного и с 
    радостью наградит активных зрителей!</p>
    
    <p>На NYAF также можно принять участие в 
    интерактивном квесте - для этого нужно лишь 
    проявить смекалку и следить за новостями в нашей группе!
     Принять участие может каждый гость в день проведения 
     фестиваля, один или в компании друзей. Во время прохождения 
     квеста Вы сможете поближе познакомиться с фестивалем, 
     узнать интересные факты и, конечно же, забрать с собой памятные сувениры! </p>
    `,
    color: "white",
  },
];

const InfoSection = () => {
  const onMouseEnterBusStop = (name: string) => {
    document.getElementById(name)?.classList.add("bus-stop-name-show");
  };

  const onMouseLeaveBusStop = (name: string) => {
    document.getElementById(name)?.classList.remove("bus-stop-name-show");
  };

  return (
    <div
      className="content-section white-section container-v center"
      style={{ paddingTop: "60px" }}
    >
      <Marquee autoFill={true} speed={90}>
        <img src="belt.png" alt="NYAF" />
      </Marquee>

      <div className="content" style={{ zIndex: "1" }}>
        <div id="what" className="container-v center">
          <div
            className="text header-text mobile"
            style={{ marginBottom: "34px", color: "#01BBF8" }}
          >
            САМЫЙ ТЁПЛЫЙ ЗИМНИЙ ФЕСТИВАЛЬ
          </div>
          <div className="container what">
            <div className="image-container">
              <img
                className="image"
                style={{ width: "100%", height: "auto", display: "block" }}
                src="What.png"
                alt="Festival"
              />
            </div>
            <div id="what-description" className="container-v what-description">
              <div
                className="text header-text desktop"
                style={{ color: "#01BBF8" }}
              >
                САМЫЙ ТЁПЛЫЙ ЗИМНИЙ ФЕСТИВАЛЬ
              </div>
              <div className="text body-text">
                {`NYAF — один из старейших и крупнейших фестивалей
              популярной молодёжной культуры города Уфа
              c неповторимой атмосферой тепла и уюта,
              ежегодно объединяющий на своей площадке
              фанатов аниме, видеоигр, комиксов, кино и сериалов, 
              настолок, k-pop и много чего еще!`}
              </div>
              <a className="text more-text" href="/about">
                Узнать больше
              </a>
            </div>
          </div>
        </div>
      </div>
      <Marquee autoFill={true} speed={90}>
        <img src="belt.png" alt="NYAF" />
      </Marquee>
      <img src="lines2.png" alt="NYAF" />
      <div className="content" style={{ zIndex: "1" }}>
        <div id="where" className="container-v center">
          <div
            className="text header-text mobile"
            style={{ marginBottom: "34px", color: "#01BBF8" }}
          >
            МЕСТО ПРОВЕДЕНИЯ
          </div>
          <div className="container where">
            <div
              id="where-description"
              className="container-v where-description"
              style={{
                margin: "30px 0px",
              }}
            >
              <div
                className="text header-text desktop"
                style={{ color: "#01BBF8" }}
              >
                МЕСТО ПРОВЕДЕНИЯ
              </div>
              <div
                style={{
                  marginTop: "40px",
                }}
                className="container body-text"
              >
                <img
                  style={{ width: "30px", marginRight: "12px" }}
                  src="pin.svg"
                  alt="Pin"
                />
                <span>
                  <b>ГКДЦ «Химик»</b>
                  <br />
                  Первомайская ул., 47
                </span>
              </div>
              <div className="container maps wrap desktop">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_47/YU8Ydg5gTUcHQFtufXRwdHtiZA==/?ll=56.090420%2C54.815710&z=16.98"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "145px" }}
                    src="yandex-maps.png"
                    alt="Yandex Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://2gis.ru/ufa/firm/2393065583058352?m=56.090426%2C54.815605%2F16"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "115px" }}
                    src="2gis-maps.png"
                    alt="2GIS Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/%22%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%BE%D0%B9+%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D0%BE-%D0%B4%D0%BE%D1%81%D1%83%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9+%D1%86%D0%B5%D0%BD%D1%82%D1%80%22/@54.813533,56.0851833,17z/data=!4m15!1m8!3m7!1s0x43d94802dce91629:0xe9267b6da897f26b!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCA0Nywg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MDExMg!3b1!8m2!3d54.8135299!4d56.0877582!16s%2Fg%2F11g8crzl85!3m5!1s0x43d949343f009fc9:0xc4c264a4621ae98d!8m2!3d54.8156028!4d56.0903521!16s%2Fg%2F11g043h6hw?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "236px" }}
                    src="google-maps.png"
                    alt="Google Maps"
                  />
                </a>
              </div>
              <div className="container maps mobile">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_47/YU8Ydg5gTUcHQFtufXRwdHtiZA==/?ll=56.090420%2C54.815710&z=16.98"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "50px" }}
                    src="yandex-maps-small.png"
                    alt="Yandex Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://2gis.ru/ufa/firm/2393065583058352?m=56.090426%2C54.815605%2F16"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "65px" }}
                    src="2gis-maps-small.png"
                    alt="2GIS Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/%22%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%BE%D0%B9+%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D0%BE-%D0%B4%D0%BE%D1%81%D1%83%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9+%D1%86%D0%B5%D0%BD%D1%82%D1%80%22/@54.813533,56.0851833,17z/data=!4m15!1m8!3m7!1s0x43d94802dce91629:0xe9267b6da897f26b!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCA0Nywg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MDExMg!3b1!8m2!3d54.8135299!4d56.0877582!16s%2Fg%2F11g8crzl85!3m5!1s0x43d949343f009fc9:0xc4c264a4621ae98d!8m2!3d54.8156028!4d56.0903521!16s%2Fg%2F11g043h6hw?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "55px" }}
                    src="google-maps-small.png"
                    alt="Google Maps"
                  />
                </a>
              </div>
              <a className="text more-text" href="/what-where-when">
                Узнать больше
              </a>
            </div>

            <div className="image-container">
              <img className="image" src="map.png" alt="Map" />
              <div
                id="bus-stop-1"
                className="bus-stop"
                style={{ left: "41%", top: "80%" }}
              >
                <div
                  id="bus-stop-name-1"
                  className="bus-stop-name"
                  style={{ marginLeft: "-16px" }}
                >
                  Городской культурно-досуговый центр
                </div>
                <img
                  src="busstop.svg"
                  alt="Bus stop"
                  width="28px"
                  onMouseEnter={() => onMouseEnterBusStop("bus-stop-name-1")}
                  onMouseLeave={() => onMouseLeaveBusStop("bus-stop-name-1")}
                />
              </div>
              <div
                id="bus-stop-2"
                className="bus-stop"
                style={{ left: "35%", top: "84.4%" }}
              >
                <div
                  id="bus-stop-name-2"
                  className="bus-stop-name"
                  style={{ marginLeft: "1px", padding: "2px 15px 0px 5px" }}
                >
                  Городской культурно-досуговый центр
                </div>
                <img
                  src="busstop.svg"
                  alt="Bus stop"
                  width="28px"
                  onMouseEnter={() => onMouseEnterBusStop("bus-stop-name-2")}
                  onMouseLeave={() => onMouseLeaveBusStop("bus-stop-name-2")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="lines3.png" alt="NYAF" />
      <div className="content" style={{ zIndex: "1" }}>
        <div className="container-v center tickets">
          <div className="text header-text" style={{ marginBottom: "20px" }}>
            КУПИТЬ БИЛЕТЫ
          </div>
          <TicketsWidget />
          <h2
            className="text"
            style={{ marginTop: "30px", marginBottom: "10px", color: "white" }}
          >
            Билеты на фестиваль NYAF можно приобрести только онлайн!
          </h2>
          <h2
            className="text"
            style={{ marginBottom: "30px", marginTop: "0px", color: "white" }}
          >
            На входе в день фестиваля билеты продаваться не будут!
          </h2>
          <div className="body-text" style={{ marginTop: "20px" }}>
            Количество билетов ограничено. Следите за новостями в наших
            социальных сетях:{" "}
            <a
              className="more-text"
              href="https://vk.com/nyafest"
              target="_blank"
              rel="noreferrer"
            >
              VK
            </a>{" "}
            и
            <a
              className="more-text"
              href="https://t.me/nyafestival"
              target="_blank"
              rel="noreferrer"
            >
              Telegram
            </a>
          </div>
          {/* <div style={{ maxWidth: "700px" }}>
            <OfflineShopsWidget />
          </div> */}
        </div>
      </div>
      <img src="lines1.png" alt="NYAF" />
      <div className="content" style={{ zIndex: "1" }}>
        <div className="container-v center awaits">
          <div className="text header-text">ВАС ЖДЁТ</div>
          <div
            className="container wrap center"
            style={{ marginTop: "20px", alignItems: "baseline" }}
          >
            {awaitsBubbles.map((bubble) => (
              <BubbleWithPopUp bubble={bubble} key={bubble.id} />
            ))}
          </div>
        </div>
      </div>
      <img src="lines2.png" alt="NYAF" />
    </div>
  );
};

export default InfoSection;
