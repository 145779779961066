import React, { useEffect } from "react";
import "./GuestsSection.css";
import { Bubble } from "./InfoSection";
import BubbleWithPopUp from "../BubbleWithPopUp";

const guestsBubbles: Bubble[] = [
  {
    id: 1,
    label: "???????",
    image: "guest.png",
    text: "?????",
    socials: [],
    color: "white",
  },
  {
    id: 2,
    label: "???????",
    image: "guest.png",
    text: "?????",
    socials: [],
    color: "white",
  },
  {
    id: 3,
    label: "???????",
    image: "guest.png",
    text: "?????",
    socials: [],
    color: "white",
  },
  // {
  //   id: 1,
  //   label: "GraysonFin",
  //   image: "GRAYSON.png",
  //   text: `<p>Евгений Васильев, больше известный как GraysonFin — косплеер с многолетним стажем и самым популярным косплей пабликом в СНГ! </p>

  //   <p>За плечами Грейсона более 100 образов разного уровня сложности, он постоянный гость на всех крупнейших фестивалях России (а с недавнего времени и Казахстана). </p>

  //   <p>«Буду безумно рад увидеться снова с офигенными ребятами и посетить ваш прекрасный город! Обязательно подходите пообщаться о косплее, жизни, а также обняться и сделать фоточку на память!»
  //   </p>`,
  //   socials: [
  //     { platform: "vk", link: "https://vk.com/graysonfincos" },
  //     { platform: "telegram", link: "https://t.me/graysonfincosplay" },
  //     { platform: "youtube", link: "https://www.youtube.com/c/GraysonFin" },
  //   ],
  //   color: "white",
  // },
  // {
  //   id: 2,
  //   label: "Tololommo",
  //   image: "Tololommo.png",
  //   text: `<p>Анастасия Цыплёнкова, так же известная как «Девятый из Хокаге» или tololommo — известный блогер, педагог,
  //   звезда Tik-Tok, яркая и интересная личность, основательница онлайн-школы «REDFORD SCHOOL».</p>

  //   <p>Она приедет на фестиваль NYAF благодаря спонсорской поддержке нашего генерального
  //   партнёра — магазина <a href="https://vk.com/gensei_shop" target="_blank" rel="noreferrer">Gensei Shop</a>!

  //   </p>`,
  //   socials: [
  //     { platform: "tiktok", link: "https://www.tiktok.com/@tololomo" },
  //     { platform: "telegram", link: "https://t.me/tololommoZK" },
  //     { platform: "youtube", link: "https://www.youtube.com/@Tololommo" },
  //   ],
  //   color: "white",
  // },
  // {
  //   id: 3,
  //   label: "Saikono Joker",
  //   image: "Saikono.png",
  //   text: `<p>Зайчик едет в Уфу! Да-да! Вам не показалось!</p>

  //   <p>Saikono Joker — создатель и главный художник игры «Зайчик», а также её продюсер и режиссёр. </p>

  //   <p>Tiny Bunny или «Зайчик» — нелинейная визуальная новелла в жанре ужасов,
  //   взорвавшая интернет, являющаяся одной из самый популярных российских indie игр
  //   в настоящее время. Игра высоко оценивается критиками и игроками,
  //   собрав 95% положительных отзывов в Steam,
  //   а летсплеи этой игры собирают миллионы просмотров на YouTube.
  //   </p>`,
  //   socials: [{ platform: "vk", link: "https://vk.com/bunnyhorror" }],
  //   color: "white",
  // },
];

const GuestsSection = () => {
  useEffect(() => {
    const vk_widget = document.getElementById("vk_groups");
    if (!vk_widget) {
      // Create the vk_groups div element
      const vkGroupsDiv = document.createElement("div");
      vkGroupsDiv.id = "vk_groups";

      // Append the vk_groups div element to the component's root element or another container element as needed
      const container = document.getElementById("vk"); // Replace 'your-container-id' with the actual container ID
      container?.appendChild(vkGroupsDiv);

      // Create the script element for the VK.Widgets.Group
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.innerHTML = `
      VK.Widgets.Group("vk_groups", {mode: 3}, 19608738);
    `;

      // Append the script element to the component's root element or another container element as needed
      container?.appendChild(scriptElement);
    }
  }, []); // The empty dependency array ensures this code runs once after the component mounts

  return (
    <div className="content-section white-section">
      <div className="container-v center">
        <div className="content">
          <div className="text header-text">ПРИГЛАШЁННЫЕ ГОСТИ</div>
          <div
            className="container wrap"
            style={{ marginTop: "20px", justifyContent: "center" }}
          >
            {guestsBubbles.map((bubble) => (
              <BubbleWithPopUp bubble={bubble} key={bubble.id} />
            ))}
          </div>
        </div>
        <img src="lines3.png" alt="NYAF" />
        <div className="content" style={{ marginBottom: "0px" }}>
          <div className="text header-text socials-text">
            МЫ В СОЦИАЛЬНЫХ СЕТЯХ
          </div>
          <div className="text header-text socials-text-mobile">
            МЫ В СОЦСЕТЯХ
          </div>
          <div
            id="our_socials"
            className="container center"
            style={{ gap: "50px", flexWrap: "wrap" }}
          >
            <div id="vk"></div>
            <a href="https://t.me/nyafestival" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/telegram.png"
                alt="telegram"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsSection;
