import {
  Button,
  debounce,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { Field, Nomination } from "../types";
import {
  deleteFieldToNomination,
  getNomination,
  getNominations,
  postFieldToNomination,
  updateFieldOrder,
} from "../../services/nominationsService";
import { getFields } from "../../services/fieldsService";
import { Delete } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";

function NominationsFields() {
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [currentNomination, setCurrentNomination] = useState<Nomination>();
  const [nominationFields, setNominationFields] = useState<Field[]>([]);
  const [fields, setFields] = useState<Field[]>([]);

  const getAllFields = async (alreadyAddedFields: Field[]) => {
    const allFields = await getFields();
    setFields(
      allFields.filter(
        (f) => !alreadyAddedFields.map((af) => af.fieldId).includes(f.fieldId)
      )
    );
  };

  const getAllNominations = async () => {
    const nominationsData = await getNominations();
    setNominations(nominationsData);
  };

  const getCurrentNomination = async (nominationId?: string) => {
    if (nominationId) {
      const nomination = await getNomination(nominationId);
      setCurrentNomination(nomination);
      const nomFields =
        nomination.nominationFields
          ?.sort((a, b) => a.order - b.order)
          .map((f) => {
            return { ...f.field, order: f.order };
          }) ?? [];
      setNominationFields(nomFields);
      getAllFields(nomFields);
    }
  };

  const addFieldToNomination = async (
    nominationId?: string,
    fieldId?: string
  ) => {
    if (nominationId && fieldId) {
      await postFieldToNomination(nominationId, fieldId);
      getCurrentNomination(nominationId);
    }
  };

  const removeFieldFromNomination = async (
    nominationId?: string,
    fieldId?: string
  ) => {
    if (nominationId && fieldId) {
      await deleteFieldToNomination(nominationId, fieldId);
      getCurrentNomination(nominationId);
    }
  };

  useEffect(() => {
    getAllNominations();
  }, []);

  const getCategoryFields = (
    nominationFields: Field[],
    categoryName: string,
    categoryCode: string
  ) => {
    var categoryFields = nominationFields.filter(
      (f) => f.category === categoryCode && !f.dependsOn
    );
    return (
      categoryFields.length !== 0 && (
        <div key={categoryCode} className="container-v" style={{ gap: "15px" }}>
          <h2>{categoryName}</h2>
          {categoryFields
            .sort((a, b) => a.order - b.order)
            .map((f) => {
              return getFieldGroup(nominationFields, f);
            })}
        </div>
      )
    );
  };

  const getFieldGroup = (
    nominationFields: Field[],
    field: Field
  ): ReactNode => {
    const dependantField = nominationFields.find(
      (f) => f.dependsOn === field.fieldId
    );
    return (
      <div
        key={field.fieldId}
        className="container field-group-dependancy"
        style={{
          gap: "20px",
        }}
      >
        {getField(field, dependantField ? "" : "field-solo")}
        {dependantField && getField(dependantField)}
      </div>
    );
  };

  const debouncedApiCall = debounce(
    async (order: number, nominationId?: string, fieldId?: string) => {
      if (nominationId && fieldId) {
        await updateFieldOrder(nominationId, fieldId, order);
        getCurrentNomination(nominationId);
      }
    },
    300
  );

  const getField = (field: Field, className = ""): ReactNode => {
    return (
      <ListItem key={field.fieldId}>
        <ListItemText primary={field.label} />
        <Input
          defaultValue={field.order}
          onChange={(e) => {
            debouncedApiCall(
              parseInt(e.target.value),
              currentNomination?.nominationId,
              field.fieldId
            );
          }}
          type="number"
          style={{ maxWidth: "70px" }}
        ></Input>
        <Button
          onClick={() => {
            if (currentNomination) {
              removeFieldFromNomination(
                currentNomination.nominationId,
                field.fieldId
              );
            }
          }}
        >
          <Delete />
        </Button>
      </ListItem>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
        <h2>Основные номинации</h2>
        <List style={{ overflowY: "auto" }}>
          {nominations.map((nom) => (
            <ListItem key={nom.nominationId}>
              <ListItemButton
                onClick={() => {
                  getCurrentNomination(nom.nominationId);
                }}
              >
                <ListItemText primary={nom.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {currentNomination && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h5>Ссылка на страницу заявки</h5>
          <a
            href={`/apply?nominationId=${currentNomination.nominationId}`}
            target="_blank"
            rel="noreferrer"
          >
            ПОДАТЬ ЗАЯВКУ
          </a>
          <h2>Поля для {currentNomination.name}</h2>
          <List style={{ overflowY: "scroll" }}>
            {getCategoryFields(
              nominationFields,
              `${
                currentNomination?.name === "Групповое косплей дефиле" ||
                currentNomination?.name === "Cover Dance"
                  ? "Данные капитана команды"
                  : "Ваши данные"
              }`,
              "personal"
            )}
            {getCategoryFields(nominationFields, "Команда", "group")}
            {getCategoryFields(
              nominationFields,
              "Информация о выступлении",
              "application"
            )}
            {getCategoryFields(
              nominationFields,
              "Материалы заявки",
              "application-media"
            )}
            {getCategoryFields(
              nominationFields,
              "Дополнительные материалы",
              "extra"
            )}
            {/* {nominationFields.map((nom) => (
              <ListItem key={nom.fieldId}>
                <ListItemButton
                  onClick={() => {
                    removeFieldFromNomination(
                      currentNomination.nominationId,
                      nom.fieldId
                    );
                  }}
                >
                  <ListItemText primary={nom.label} />
                </ListItemButton>
              </ListItem>
            ))} */}
          </List>
        </div>
      )}
      {currentNomination && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>Доступные поля</h2>
          <List style={{ overflowY: "scroll" }}>
            {fields.map((nom) => (
              <ListItem
                key={nom.fieldId}
                style={{ justifyContent: "space-between", gap: "12px" }}
              >
                <Button
                  style={{ maxWidth: "30px" }}
                  onClick={() => {
                    addFieldToNomination(
                      currentNomination.nominationId,
                      nom.fieldId
                    );
                  }}
                >
                  <Add />
                </Button>
                <ListItemText primary={nom.label} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default NominationsFields;
